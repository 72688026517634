import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import { Icon } from '#app/components/ui/icon'

const SocialMediaIconBundle = () => {
	let { t } = useTranslation('footer')

	return (
		<div className="flex flex-grow-0 flex-col gap-4">
			<div className="flex gap-2">
				<PlentyBody size={'md'}>{t('find_us_so_me')}</PlentyBody>

				<a
					href="https://www.instagram.com/plentyand_/"
					target="_blank"
					rel="noopener noreferrer"
					className="group flex items-center gap-2"
				>
					<Icon className="text-body-md" size="sm" name="instagram" />
				</a>
				<a
					href="https://www.linkedin.com/company/plentyand/"
					target="_blank"
					rel="noopener noreferrer"
					className="group flex items-center gap-2"
				>
					<Icon className="text-body-md" size="sm" name="linkedin" />
				</a>
				<a
					href="https://www.pinterest.dk/plentyand/"
					target="_blank"
					rel="noopener noreferrer"
					className="group flex items-center gap-2"
				>
					<Icon className="text-body-md" size="sm" name="pinterest" />
				</a>
				<a
					href="https://www.facebook.com/profile.php?id=100089802061958"
					target="_blank"
					rel="noopener noreferrer"
					className="group flex items-center gap-2"
				>
					<Icon className="text-body-md" size="sm" name="facebook" />
				</a>
			</div>
			<div className="flex max-w-[300px] flex-col space-y-1">
				<a
					href="https://bestseller.com/"
					target="_blank"
					rel="noopener noreferrer"
					className="cursor-pointer"
				>
					<PlentyBody size="xs">
						{t('object_vila_footer_disclaimer')}
					</PlentyBody>
				</a>
				<a
					href="https://www.bestseller.com/"
					target="_blank"
					rel="noopener noreferrer"
					className="cursor-pointer"
				>
					<PlentyBody size="xs">{t('bestseller_footer_disclaimer')}</PlentyBody>
				</a>
			</div>
		</div>
	)
}

export default SocialMediaIconBundle
