import { Await } from '@remix-run/react'
import React from 'react'

import { LoadingSpinner } from '#app/components/ui/spinner'
import ErrorPage from '#app/features/checkout/components/error-page'
import { type Outfit } from '#types/outfit.ts'
import ShopTheLookCarouselComponent from './shop-the-look-carousel-component'

interface ShopTheLookCarouselProps {
	outfitsByStyle: Outfit[]
	title?: string
	subtitle?: string
	linkUrl?: string
	linkText?: string
}

const ShopTheLookLandingCarousel = ({
	outfitsByStyle,
	title,
	subtitle,
	linkUrl,
	linkText,
}: ShopTheLookCarouselProps) => {
	return (
		<>
			<React.Suspense fallback={<LoadingSpinner />}>
				<Await resolve={outfitsByStyle} errorElement={<ErrorPage />}>
					{outfitsByStyle.length > 0 && (
						<div className="space-y-4 md:space-y-8 ">
							<ShopTheLookCarouselComponent
								outfitsByStyle={outfitsByStyle}
								title={title}
								subtitle={subtitle}
								linkUrl={linkUrl}
								linkText={linkText}
							/>
						</div>
					)}
				</Await>
			</React.Suspense>
		</>
	)
}

export default ShopTheLookLandingCarousel
