import { Link } from '@remix-run/react'
import { cn } from '#app/utils/misc'

interface NavItemProps {
	link: string
	name: string
	isActive: boolean
}

const NavItem = ({ link, name, isActive }: NavItemProps) => {
	return (
		<div key={link} className={cn('group hidden md:block')}>
			<div className={cn('peer relative', isActive && 'font-bold')}>
				<Link
					to={link}
					className={cn(
						'text-[16px] font-normal text-beige-100',
						isActive && 'font-bold text-black-90',
					)}
					unstable_viewTransition={true}
				>
					{name}
				</Link>
				<div
					className={cn(
						'absolute -bottom-6 left-[30%] z-100 h-[2px] rounded-full bg-black-90 transition-all',
						isActive ? 'w-[40%] ' : 'w-0 group-hover:w-[40%]',
					)}
				/>
			</div>
		</div>
	)
}

export default NavItem
