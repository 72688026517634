import { useFetcher } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import { useEffect } from 'react'
import ShopTheLookLandingCarousel from '#app/features/shop-the-look/carousel/shop-the-look-landing-carousel.tsx'
import { type action } from '#app/routes/resources+/storyblok+/getOutfits.tsx'
import { type ShopTheLookSliderStoryblok } from '#types/component-types-sb'

interface ShopTheLookSliderProps {
	blok: ShopTheLookSliderStoryblok
}

const ShopTheLookSlider = ({ blok }: ShopTheLookSliderProps) => {
	const outfitsByStyle = useFetcher<typeof action>()

	const title = blok.title ?? ''
	const subtitle = blok.subtitle ?? ''
	const linkUrl = blok.linkUrl ?? ''
	const linkText = blok.linkText ?? ''
	const gender = blok.gender ?? ''
	const store = blok.store ?? ''
	const sort = blok.sort ?? ''

	useEffect(() => {
		if (!outfitsByStyle.data && outfitsByStyle.state === 'idle') {
			const formData = new FormData()
			formData.append('gender', gender)
			formData.append('store', store)
			formData.append('sort', sort)
			outfitsByStyle.submit(formData, {
				method: 'post',
				action: '/resources/storyblok/getOutfits',
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!outfitsByStyle.data) {
		return null
	}

	return (
		<div {...storyblokEditable(blok)}>
			<ShopTheLookLandingCarousel
				outfitsByStyle={outfitsByStyle.data.outfitsByStyle}
				title={title}
				subtitle={subtitle}
				linkUrl={linkUrl}
				linkText={linkText}
			/>
		</div>
	)
}

export default ShopTheLookSlider
