import { WheelGesturesPlugin } from '#node_modules/embla-carousel-wheel-gestures'
import { Link } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body.tsx'
import PlentyHeading from '#app/components/typography/plenty-heading.tsx'
import { Button } from '#app/components/ui/button.tsx'
import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from '#app/components/ui/carousel.tsx'
import PlentyBodyLink from '#app/components/ui/plenty-body-link.tsx'
import { type Outfit } from '#types/outfit.ts'
import { getProductPrice } from '../utils'

interface ShopTheLookCarouselComponentProps {
	outfitsByStyle: Outfit[]
	title?: string
	subtitle?: string
	linkUrl?: string
	linkText?: string
}

const ShopTheLookCarouselComponent = ({
	outfitsByStyle,
	title,
	subtitle,
	linkUrl,
	linkText,
}: ShopTheLookCarouselComponentProps) => {
	let { t } = useTranslation('shop_the_look')

	return (
		<>
			<div id="title" className="flex flex-col justify-start">
				{title != '' && (
					<PlentyHeading className="md:pb-2" as="h7">
						{title}
					</PlentyHeading>
				)}
				<div className="flex flex-col md:flex-row md:justify-between md:pr-32">
					<PlentyBody className=" pb-2">{subtitle}</PlentyBody>
					<Link to={linkUrl!} className="flex flex-row gap-2">
						{linkText != '' && (
							<PlentyBodyLink as="nav-sm">{linkText}</PlentyBodyLink>
						)}
					</Link>
				</div>
			</div>

			<Carousel plugins={[WheelGesturesPlugin()]} opts={{ dragFree: true }}>
				<CarouselContent className="ml-[1px] gap-4">
					{outfitsByStyle.map((outfit: any, index: any) => (
						<CarouselItem
							key={index}
							className="flex max-h-[896px] basis-auto flex-col rounded-lg border border-beige-90 pl-0 md:max-h-[548px] md:flex-row "
						>
							{outfit.mediaType === 'VIDEO' ? (
								<video
									src={outfit.mediaUrl}
									autoPlay
									muted
									loop
									playsInline
									className="h-[365px] max-w-[300px] rounded-t-lg object-cover transition duration-300 ease-in-out md:h-[548px] md:max-w-[284px] md:rounded-l-lg md:rounded-tr-none"
								/>
							) : (
								<img
									src={outfit.mediaUrl}
									alt={outfit.name}
									className="h-[365px] max-w-[300px] rounded-t-lg object-cover transition duration-300 ease-in-out md:h-[548px] md:max-w-[284px] md:rounded-l-lg md:rounded-tr-none"
								/>
							)}

							<div className="flex h-full max-w-[284px] flex-col justify-between px-4 py-4 md:pl-4">
								<div>
									{outfit.styleVariants.slice(0, 3).map((styleVariant: any) => (
										<div
											key={styleVariant.productId}
											className="flex flex-row items-center gap-4 pb-4"
										>
											<img
												src={styleVariant.variants[0]?.images[0]?.url || ''}
												alt={styleVariant.productName}
												className="h-[104px] max-w-[84px] rounded-sm"
											/>
											<div className="flex flex-col items-start">
												<PlentyBody className="text-beige-100">
													{
														styleVariant.variants[0].attributes.find(
															(a: any) => a.name === 'brand',
														).value
													}
												</PlentyBody>
												<PlentyBody className="text-black-90">
													{styleVariant.productName}
												</PlentyBody>
												<PlentyBody className="text-black-90">
													{getProductPrice(styleVariant.variants)}
												</PlentyBody>
											</div>
										</div>
									))}
									{outfit.styleVariants.length > 3 && (
										<PlentyBody className="pb-2 md:p-0" size="sm">
											{t('and_more_products', {
												count: outfit.styleVariants.length - 3,
											})}
										</PlentyBody>
									)}
								</div>
								<Link
									to={`/shop-the-look/${outfit.key}`}
									className="flex flex-row justify-center md:justify-start"
								>
									<Button
										variant="secondary"
										size="secondary"
										className="flex flex-row items-center justify-center"
									>
										{t('shop_the_look')}
									</Button>
								</Link>
							</div>
						</CarouselItem>
					))}
				</CarouselContent>
			</Carousel>
		</>
	)
}

export default ShopTheLookCarouselComponent
