import { type Price, type TypedMoney } from '@commercetools/platform-sdk'

export const centPriceToPrice = (centAmount: number, digits: number) => {
	const divideValue = Math.pow(10, digits ?? 0)
	return (centAmount ?? 0) / divideValue
}

export const moneyFormat = (
	value: number,
	currency: string,
	language: string,
) => {
	const formatted = new Intl.NumberFormat(language, {
		style: 'currency',
		currency: currency,
	}).format(value)
	return formatted.endsWith('.') ? formatted.slice(0, -1) : formatted
}

export const moneyFormatWithTypedMoney = (
	typedMoney: TypedMoney | undefined,
) => {
	if (!typedMoney) return 'N/A'
	const formatted = new Intl.NumberFormat('da-DK', {
		style: 'currency',
		currency: typedMoney.currencyCode ?? 'DKK',
	}).format(centPriceToPrice(typedMoney.centAmount, typedMoney.fractionDigits))
	return formatted.endsWith('.') ? formatted.slice(0, -1) : formatted
}

export const discountedPrice = (price: Price) => {
	return price.discounted ? price.discounted.value : price.value
}

export const formatPrice = (price: Price) => {
	const lowestPrice = discountedPrice(price)
	return moneyFormat(
		centPriceToPrice(lowestPrice.centAmount ?? 0, lowestPrice.fractionDigits),
		lowestPrice.currencyCode ?? 'DKK',
		'da-DK',
	)
}

export const formatPrices = (price: Price[]) => {
	const lowestPrices = price.map(p => discountedPrice(p))

	const accumulatedLowestPrices = lowestPrices.reduce(
		(accumulator, currentPrice) => {
			return accumulator + currentPrice.centAmount
		},
		0,
	)

	return moneyFormat(
		centPriceToPrice(accumulatedLowestPrices ?? 0, 2),
		'DKK',
		'da-DK',
	)
}

export const compareAndReturnLowestPrice = (
	firstPrice: Price,
	secondPrice: Price,
) => {
	const firstPriceValue = discountedPrice(firstPrice)
	const secondPriceValue = discountedPrice(secondPrice)
	if (firstPriceValue.centAmount < secondPriceValue.centAmount) {
		return firstPrice
	} else {
		return secondPrice
	}
}
