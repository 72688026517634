import {
	type Price,
	type ProductVariant,
} from '#node_modules/@commercetools/platform-sdk/dist/commercetools-platform-sdk.cjs'
import {
	compareAndReturnLowestPrice,
	formatPrice,
	formatPrices,
} from '#app/utils/money.ts'
import { type OutfitStyleVariant } from '#types/outfit.ts'

export const getTotalPrice = (styleVariants: OutfitStyleVariant[]): string => {
	const lowestPrices = styleVariants.map((styleVariant: OutfitStyleVariant) => {
		const variants = styleVariant.variants
		const prices = getPricesfromVariants(variants)
		const lowestPrice = getLowestPriceFromPrices(prices)
		return lowestPrice
	})

	return formatPrices(lowestPrices)
}

export const getProductPrice = (variants: ProductVariant[]): string => {
	const prices = getPricesfromVariants(variants)
	const lowestPrice = getLowestPriceFromPrices(prices)
	return formatPrice(lowestPrice)
}

const getPricesfromVariants = (variants: ProductVariant[]): Price[] => {
	return variants.flatMap(
		(variant: ProductVariant) => variant.prices?.filter(p => p != null) ?? [],
	)
}

const getLowestPriceFromPrices = (prices: Price[]): Price => {
	return prices.reduce(compareAndReturnLowestPrice)
}
