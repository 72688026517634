import { useNavigate } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import PlentyHeading from '#app/components/typography/plenty-heading'
import { Button } from '#app/components/ui/button'
import { Icon } from '#app/components/ui/icon'

export const ErrorPage = () => {
	const navigate = useNavigate()
	const { t } = useTranslation('error')

	return (
		<div className="flex min-h-[64vh] flex-col items-center justify-center ">
			<div>
				<div className="relative w-fit">
					<Icon
						name="bubbles"
						size="font"
						className="h-[95px] w-[155px] text-mint-green-80p md:h-[200px] md:w-[300px]"
					/>
					<div className="absolute left-[50%] top-[50%] w-full -translate-x-[50%] -translate-y-[50%] text-center">
						<img src="/img/sad404.svg" alt="error icon" />
					</div>
				</div>
			</div>
			<div className="flex flex-col items-center px-4">
				<PlentyHeading as="h5">{t('something_wrong')}</PlentyHeading>

				<Button
					size="primary"
					onClick={() => navigate('/shopping-bag')}
					className="mt-4"
				>
					{t('go_to_shopping')}
				</Button>
			</div>
		</div>
	)
}

export default ErrorPage
